export const isLinkAccessable = (rolesAssigned = [], userRoles) => {
  if(rolesAssigned.length === 0){
    return true;
  }
  const matchedRoles = rolesAssigned.filter(value => userRoles.includes(value));

  if(matchedRoles.length > 0){
    return true
  }
  return false;
}
