// import { dataFetcher } from 'core/services/requestHandler';   
import { requestHandler } from 'core/services/httpRequest'; 

const getPendingPayments = async () => {
  return requestHandler.GET({url: '/payment/unpaid'});
};

export function getPaymentTypes(){
  return requestHandler.GET({url: '/payment/options'});
}

const confirmRentPayment = async (body = {}, config = {}) => {
  return requestHandler.POST({url: '/payment/approval', body});
};

const getInmatePaymentHistory = async (data = {}, config = {}) => {
  return requestHandler.GET({url: '/payment/history'});
};

export { getPendingPayments, confirmRentPayment, getInmatePaymentHistory };
