import React, { useState, useEffect, useCallback } from 'react';
import { Card, CardContent, Typography, Divider, Button, CardActions, Paper, Grid, Fab } from '@mui/material';
import { Box } from '@mui/system';
import { getPaymentTypes, getPendingPayments, } from 'services/payRent';
import { useSnackbar } from 'notistack';
import ScrollDialog from 'components/ScrollDialog';
import PaymentConfirmation from './PaymentConfirmation';
import PaymentDetails from './PaymentDetails';
import PaymentHostory from './PaymentHistory';
import HistoryIcon from '@mui/icons-material/History';

const PayRent = () => {

  /* Hooks */
  const { enqueueSnackbar } = useSnackbar();
  const [pendingPayments, setPendingPayments] = useState([]);
  const [paymentId, setPaymentId] = useState(null);
  const [openPaymentConfirmationModal, setOpenPaymentConfirmationModal] = useState(false);
  const [openPaymentDetailsModal, setOpenPaymentDetailsModal] = useState(false);
  const [openPaymentHistoryModal, setOpenPaymentHistoryModal] = useState(false);
  const [paymetOptions, setPaymetOptions] = useState([]);

  useEffect(() => {
    getPendingRents();
    getPaymentOptions();
  }, []);

  function getPendingRents() {
    getPendingPayments().then(({response, error}) => {
      if(error){
        setPendingPayments([]);
        enqueueSnackbar((error?.message || error), {variant: 'error'});
      } else {
        setPendingPayments([...(response || [])]);
      }
    });
  }

  function getPaymentOptions() {
    getPaymentTypes().then(({response, error}) => {
      if(error){
        setPaymetOptions([]);
        // enqueueSnackbar((error?.message || error), {variant: 'error'});
      } else {
        setPaymetOptions([...(response || [])]);
      }
    });
  }

  function showPaymentConfirmationDialog(event, paymentId) {
    setPaymentId(paymentId);
    setOpenPaymentConfirmationModal(true);
    setOpenPaymentDetailsModal(false);
  }

  const onModalClose = useCallback(
    () => {
      setOpenPaymentConfirmationModal(false);
      setOpenPaymentDetailsModal(false);
      setOpenPaymentHistoryModal(false);
      setPaymentId(null);
    },
    [],
  );

  const onPaymentConfirmSuccess = useCallback(
    () => {
      setOpenPaymentConfirmationModal(false);
      setPaymentId(null);
      getPendingRents();
    },
    [],
  );


  function showPaymentDetailsDialog(event, paymentId) {
    setPaymentId(paymentId);
    setOpenPaymentConfirmationModal(false);
    setOpenPaymentDetailsModal(true);
  }

  function showPaymentHistoryDialog(event,) {
    setOpenPaymentConfirmationModal(false);
    setOpenPaymentDetailsModal(false);
    setOpenPaymentHistoryModal(true);
  }

  return (
    <Paper sx={{ minWidth: 275, py: 2, px: 3, minHeight: "calc(100vh - 100px )" }}>
      <Typography component="h2" variant="h6" color="primary" gutterBottom sx={{mb: 1}}>
        Pending Payments
      </Typography>
      <Divider />
      <Box noValidate  sx={{ mt: 2, pb: 10 }} >
        <Grid container spacing={3}>
          {pendingPayments.map(({paymentId, amountDue, month, description}) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={paymentId}>
              <Card sx={{ minWidth: 275 }} >
                <CardContent>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {month}
                  </Typography>
                  <Typography variant="h5" component="div">
                    Rs. {amountDue}/-
                  </Typography>
                  <small>
                    {description}
                  </small>
                </CardContent>
                <Divider />
                <CardActions>
                  <Button size="small" sx={{ fontSize: 12, textTransform: 'none', }} onClick={(e) => showPaymentDetailsDialog(e, paymentId)}>Pay Now</Button>
                  <Button color={'secondary'} size="small" sx={{ fontSize: 11, textTransform: 'none' }} onClick={(e) => showPaymentConfirmationDialog(e, paymentId)}>Already Paid</Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
        <ScrollDialog onModalClose={onModalClose} open={openPaymentConfirmationModal} title="Confirm Payment">
          <PaymentConfirmation paymentId={paymentId} onPaymentConfirmSuccess={onPaymentConfirmSuccess} />
        </ScrollDialog>
        <Fab variant="extended"  aria-label="Create Issue" sx={{ position: 'absolute', bottom: 48,right: 48, textTransform: 'inherit'}} onClick={showPaymentHistoryDialog}>
          <HistoryIcon /> Payment History
        </Fab>
      </Box>
      <ScrollDialog onModalClose={onModalClose} open={openPaymentDetailsModal} title="Payment Details">
        <PaymentDetails paymentId={paymentId} paymetOptions={paymetOptions}/>
      </ScrollDialog>
      <ScrollDialog onModalClose={onModalClose} open={openPaymentHistoryModal} title="Payment History">
        <PaymentHostory />
      </ScrollDialog>
    </Paper>
  );
}
 
export default PayRent;
