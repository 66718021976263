import React, { useState, useMemo } from 'react';
import { Box, Paper, Accordion, AccordionDetails, AccordionSummary, Typography, TableContainer, Table, TableBody, TableRow, TableCell, Link, Button} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const PaymentDetails = ({paymetOptions=[]}) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const paymentTypes = useMemo(() => {
    const data = {};
    paymetOptions.forEach((element) => {
      data[element.type] = element
    });
    return data;
  }, [paymetOptions]);
  

  return paymetOptions?.length === 0 ? <Typography variant='subtitle'>No Data found</Typography> : (
    <Box sx={{backgroundColor: '#E7EBF0', p: 3}}>
      {paymentTypes && paymentTypes['UPI'] ? 
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ width: '33%', flexShrink: 0 }}>
              UPI
            </Typography>
          
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper} >
              <Table sx={{ minWidth: 320 }} aria-label="simple table">
                <TableBody sx={{border: '1px solid #ddd'}}>
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">UPI ID: </TableCell>
                    <TableCell align="right"><strong>{paymentTypes['UPI']?.upiId}</strong></TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">Bank:</TableCell>
                    <TableCell align="right"><strong>{paymentTypes['UPI']?.bank}</strong></TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">Account Holder Name:</TableCell>
                    <TableCell align="right"><strong>{paymentTypes['UPI']?.accountHolderName}</strong></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion> 
      : null}
      {paymentTypes && paymentTypes['Net Banking'] ? 
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ width: '33%', flexShrink: 0 }}>
              IMPS/NEFT
            </Typography>
          
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper} >
              <Table sx={{ minWidth: 320 }} aria-label="simple table">
                <TableBody sx={{border: '1px solid #ddd'}}>
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">Account Number: </TableCell>
                    <TableCell align="right"><strong>{paymentTypes['Net Banking']?.accountNumber}</strong></TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">IFSC:</TableCell>
                    <TableCell align="right"><strong>{paymentTypes['Net Banking']?.ifsc}</strong></TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">Bank:</TableCell>
                    <TableCell align="right"><strong>{paymentTypes['Net Banking']?.bank}</strong></TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">Branch:</TableCell>
                    <TableCell align="right"><strong>{paymentTypes['Net Banking']?.branch}</strong></TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">Account Holder Name:</TableCell>
                    <TableCell align="right"><strong>{paymentTypes['Net Banking']?.accountHolderName}</strong></TableCell>
                  </TableRow>

                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion> 
      : null}
      {/* <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
            IMPS/NEFT
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit
            amet egestas eros, vitae egestas augue. Duis vel est augue.
          </Typography>
        </AccordionDetails>
      </Accordion> */}
      {paymentTypes && paymentTypes['Payment Link'] ? 
        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography sx={{ width: '33%', flexShrink: 0 }}>
              Payment Link
            </Typography>
          
          </AccordionSummary>
          <AccordionDetails>
            <Link href={paymentTypes['Payment Link'].paymentLink} target="_blank" rel="noreferrer" underline="none">
              <Button variant="contained">Pay</Button>
            </Link>
          </AccordionDetails>
        </Accordion> 
      : null}
    </Box>
  );
}
 
export default PaymentDetails;
